<template>
  <UiComponentWrapper
    class="create-an-account"
    @mousemove="onMouseMove"
  >
    <UiComponentContainer class="inner">
      <UiComponentWrapper class="background">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <defs>
            <mask id="mask">
              <radialGradient id="gradient">
                <stop
                  offset="25%"
                  stop-color="white"
                />
                <stop
                  offset="100%"
                  stop-color="black"
                />
              </radialGradient>
              <circle
                id="outer"
                :cx="mask.x"
                :cy="mask.y"
                :r="mask.r"
                fill="url(#gradient)"
              />
            </mask>
          </defs>
          <image
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            xlink:href="https://livestorm.imgix.net/1127/1701946962-pattern.png?fm=png&auto=format&q=90"
            mask="url(#mask)"
          />
        </svg>
      </UiComponentWrapper>
      <UiComponentWrapper class="overlay">
        <UiComponentWrapper class="logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="81"
            height="81"
            viewBox="0 0 81 81"
            fill="none"
          >
            <g clip-path="url(#clip0_4822_4080)">
              <path
                d="M80.4937 40.7432C80.4937 51.7912 80.8642 64.6572 72.6412 72.9083C64.4181 81.1593 51.5451 80.7397 40.4971 80.7397C29.4492 80.7397 16.5832 81.1103 8.33213 72.8873C0.0811013 64.6642 0.500645 51.7912 0.500645 40.7432C0.500645 29.6953 0.13704 16.8292 8.3601 8.57822C16.5832 0.327195 29.4562 0.746739 40.4971 0.746739C51.5381 0.746739 64.4111 0.383134 72.6622 8.60619C80.9132 16.8292 80.4937 29.7022 80.4937 40.7432Z"
                fill="white"
              />
              <path
                d="M45.0069 19.835H19.4218C17.1588 19.835 15.3242 21.6695 15.3242 23.9325C15.3242 26.1955 17.1588 28.03 19.4218 28.03H45.0069C47.27 28.03 49.1045 26.1955 49.1045 23.9325C49.1045 21.6695 47.27 19.835 45.0069 19.835Z"
                :fill="fillColor"
              />
              <path
                d="M53.3839 36.6445H27.7987C25.5357 36.6445 23.7012 38.4791 23.7012 40.7421C23.7012 43.0051 25.5357 44.8396 27.7987 44.8396H53.3839C55.6469 44.8396 57.4814 43.0051 57.4814 40.7421C57.4814 38.4791 55.6469 36.6445 53.3839 36.6445Z"
                :fill="fillColor"
              />
              <path
                d="M45.0071 53.4619H36.1757C33.9127 53.4619 32.0781 55.2964 32.0781 57.5595C32.0781 59.8225 33.9127 61.657 36.1757 61.657H45.0071C47.2701 61.657 49.1046 59.8225 49.1046 57.5595C49.1046 55.2964 47.2701 53.4619 45.0071 53.4619Z"
                :fill="fillColor"
              />
              <path
                d="M65.6765 23.9325C65.6765 21.6695 63.842 19.835 61.579 19.835C59.316 19.835 57.4814 21.6695 57.4814 23.9325C57.4814 26.1955 59.316 28.03 61.579 28.03C63.842 28.03 65.6765 26.1955 65.6765 23.9325Z"
                :fill="fillColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_4822_4080">
                <rect
                  width="80"
                  height="80"
                  fill="white"
                  transform="translate(0.5 0.746094)"
                />
              </clipPath>
            </defs>
          </svg>
        </UiComponentWrapper>
        <UiComponentHeading :level="2">
          {{ props.title }}
        </UiComponentHeading>
        <UiComponentParagraph>{{ props.text }}</UiComponentParagraph>
        <UiComponentWrapper class="actions">
          <AtomButton
            :action="props.signupOrUpgradeCallToAction.action"
            label="Create a free account section CTA"
            size="l"
            :theme="props.theme"
            :to="props.signupOrUpgradeCallToAction.to"
          >
            {{ props.signupOrUpgradeCallToAction.slot }}
          </AtomButton>
          <AtomButton
            :action="props.requestDemoCallToAction.action"
            label="Create a free account section CTA"
            size="l"
            :to="props.requestDemoCallToAction.to"
            type="secondary"
          >
            {{ props.requestDemoCallToAction.slot }}
          </AtomButton>
        </UiComponentWrapper>
        <UiComponentWrapper class="features">
          <UiComponentWrapper class="item">
            <UiComponentIcon type="check" />
            {{ props.componentGlobal.noCreditCard }}
          </UiComponentWrapper>
          <UiComponentWrapper class="item">
            <UiComponentIcon type="check" />
            {{ props.componentGlobal.noInstall }}
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentContainer>
  </UiComponentWrapper>
</template>

<script setup>
// Composables
const { width: screenWidth } = useScreenSize()

// Props
const props = defineProps({
  componentGlobal: {
    required: true,
    type: Object,
  },
  requestDemoCallToAction: {
    required: true,
    type: Object,
  },
  signupOrUpgradeCallToAction: {
    required: true,
    type: Object,
  },
  text: {
    required: true,
    type: String,
  },
  title: {
    required: true,
    type: String,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Refs
const mask = ref({ r: 40, x: 50, y: 0 })
const requestAnimationFrameId = ref(null)
const target = ref({ r: 40, x: 50, y: 0 })

// Computed Properties
const fillColor = computed(() => {
  return props.theme === 'light' ? '#12262B ' : '#1E373D'
})

// Methods
function lerp(base, target) {
  return Object
    .entries(base)
    .map(([attribute, value]) => ({
      [attribute]: value + ((target[attribute] - value) * 0.1),
    }))
    .reduce((accumulator, current) => {
      return {
        ...accumulator,
        ...current,
      }
    }, {})
}

function loop() {
  mask.value = lerp(mask.value, target.value)
  requestAnimationFrameId.value = window.requestAnimationFrame(loop)
}

function onMouseMove({ currentTarget, x, y }) {
  target.value = {
    r: 50 - (30 * (Math.abs((screenWidth.value / 2) - x) / (screenWidth.value / 2))),
    x: (x / screenWidth.value) * 100,
    y: (y / currentTarget.offsetHeight) * 100,
  }
}

// Lifecycle Hook
onBeforeUnmount(() => {
  window.cancelAnimationFrame(requestAnimationFrameId.value)
})

onMounted(() => {
  loop()
})
</script>

<style lang="postcss" scoped>
.create-an-account {
  @apply justify-center mt-10;

  .inner {
    @apply relative justify-center h-[751px];

    @screen md {
      @apply h-auto;
    }

    .background {
      @apply h-auto max-w-none w-auto -z-10;

      svg {
        @apply h-[417.33px] w-auto;

        @screen md {
          @apply h-[939px];
        }
      }
    }

    .overlay {
      @apply absolute top-0 flex-col items-center w-auto px-4;

      @screen md {
        @apply px-0;
      }

      .logo {
        @apply h-12 w-12 mt-30;

        @screen md {
          @apply h-20 w-20 mt-[276px];
        }

        svg {
          @apply h-auto w-auto;
        }
      }

      .h2 {
        @apply mt-6 text-center text-white text-4xl leading-14;

        @screen md {
          @apply mt-10;
        }

        @screen mdminus {
          @apply font-normal text-5xl leading-16;
        }

        @screen mdplus {
          @apply font-normal text-6xl leading-20;
        }

        & + p {
          @apply mt-4 text-center text-[#E7F0FF];
        }
      }

      .actions {
        @apply flex-col mt-6 gap-4;

        @screen md {
          @apply flex-row flex-wrap gap-6 w-auto;
        }

        .button {
          @screen md {
            @apply self-start;
          }
        }
      }

      .features {
        @apply flex-col items-center gap-4 mt-8;

        @screen md {
          @apply flex-row flex-wrap w-auto;
        }

        .item {
          @apply w-auto items-center font-medium text-white text-sm leading-6;

          .icon {
            @apply mr-2 font-normal text-white text-base leading-none;
          }
        }
      }
    }
  }
}
</style>
